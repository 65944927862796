.caroussel002 {
    width: 100%;
    height: 100%;
    /* min-height: 100%;
    min-width: 100%; */
    border-radius: .5rem;
    overflow: hidden;
    /* border: .1rem solid var(--dividerColor); */
    display: flex;
    flex-direction: column;
    grid-gap: .5rem;
    user-select: none;
}

.caroussel002_preview {
    width: 100%;
    height: 100%;
    background-color: var(--cardColor);
    position: relative;
    overflow: hidden;
    border-radius: .5rem;
}

.caroussel002_preview:hover .caroussel002_preview_actions{
    position: absolute;
    display: flex;
    justify-content: flex-end;
    grid-gap: .5rem;
    right: 0;
    padding: .5rem;
} 

.caroussel002_preview_loader{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.50);
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
}

.caroussel002_preview_actions{
    display: none;
}


.caroussel002_preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.caroussel002_items {
    display: flex;
    /* padding-left: 4rem; */
    height: 8rem;
    padding-bottom: .3rem;
    overflow: auto;
    position: relative;
    scroll-behavior: smooth;
    justify-content: space-between;
}

.caroussel002_preview_actions_button{
    height: 2.5rem;
    min-height: 2.5rem;
    width: 2.5rem;
    min-width: 2.5rem;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: var(--primaryColor);
    z-index: 1040;
}

.caroussel002_preview_actions_button i{
    color: white;
}

.caroussel002_items_icons {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: var(--cardColor);
    height: 2.5rem;
    min-height: 2.5rem;
    width: 2.5rem;
    min-width: 2.5rem;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    cursor: pointer;
    position: sticky;
    top: 0;
    bottom: 0;
    margin: auto 0;
    margin-left: .5rem;
    margin-right: .5rem;
}

.caroussel002_items_icons:hover{
    background-color: var(--primaryColor);
}
.caroussel002_items_icons:hover i{
    color: white;
}

.caroussel002_items_icons_left {
    left: .5rem;

}

.caroussel002_items_icons_right{
    right: .5rem;
}




.caroussel002_items::-webkit-scrollbar {
    width: 0rem;
    height: 0rem;
}

.caroussel002_items_child {
    width: 10rem;
    min-width: 10rem;
    height: 100%;
    border-radius: .4rem;
    margin-right: .5rem;
    cursor: pointer;
    overflow: hidden;
}

.add_files_card {
    background-color: var(--cardColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: .2rem;
}

.add_files_card p {
    font-size: small;
}