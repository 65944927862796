.houses_card,
.hotel_card {
    width: 100%;
    height: 100%;
    background-color: var(--cardColor);
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.show_actif_status {
    position: absolute;
    top: 0;
    z-index: 12;
    padding: .5rem;
    background-color: var(--primaryColor);
    border-bottom-right-radius: 1rem;
}

.houses_card:hover .remove_fav{
    display: block;
}

.remove_fav{
    right: 0;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 1rem;
    display: none;
    cursor: pointer;
}

#show_actif_status_disabled {
    background-color: var(--error);
}

.show_actif_status i {
    color: white;
}



.houses_card_type_indic h6 {
    color: var(--textColorOnColoredCard);
    font-size: small;
    font-weight: 700;
}

.houses_card_details_price_like {
    width: 100%;
    justify-content: space-between;
}

.houses_card_details_price_like i {
    font-size: 2rem;
    color: var(--dividerColor);
}

.houses_card_details_price_like i:hover {
    cursor: pointer;
}

.houses_card_details {
    padding: .8rem;
    display: flex;
    flex-direction: column;
    grid-gap: .4rem;
    /* height: 50%; */
}

.houses_card_details {
    cursor: pointer;
}

.houses_card_details_more {
    justify-content: flex-start;
    align-items: center;
    grid-gap: .5rem;
    /* width: 100%;
    height: 1rem; */
    /* background-color: red; */
}

.houses_card_details_more h6 {
    font-weight: 500;
    width: 100%;
    text-overflow: ellipsis;
}

.houses_card_details_pricing_table {
    width: 100%;
    display: flex;
    /* border-bottom: 1px solid var(--dividerColor); */
    justify-content: space-between;
    align-items: center;
}

.houses_card_details_pricing_table_left,
.houses_card_details_pricing_table_right {
    width: fit-content;
    padding-right: .5rem;
    width: 50%;
    flex-wrap: nowrap;
    white-space: nowrap;
    /* overflow: hidden; */
}

.houses_card_details_pricing_table_right p,
.houses_card_details_pricing_table_left p {
    font-size: small;
}

.houses_card_details_pricing_table_right h5,
.houses_card_details_pricing_table_left h5 {
    font-weight: 600;
    text-overflow: ellipsis;
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
}


.update_houses_screen {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    background-color: var(--bgColor);
}

.media_query_tester {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: red;
    display: flex;
    flex-direction: row;
}

.update_houses_screen_left,
.update_houses_screen_right {
    width: 50%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.update_houses_screen_left {
    border-right: .1rem solid var(--dividerColor);
}

.update_houses_screen_right {
    /* width: 100%; */
    padding: .8rem;
    width: 50%;
    height: 100%;
    grid-gap: .5rem;
    /* position: absolute; */
    right: 0;
    --webkit-animation-name: position;
    --webkit-animation-duration: .5s;
    animation-name: position;
    animation-duration: .5s;
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
}

.update_building {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
}

.update_building_header {
    padding: .5rem .5rem;
}

.update_actions {
    width: 100%;
    display: flex;
    grid-gap: 1rem;
    justify-content: flex-end;
    padding: .7rem;
    border-bottom: .1rem solid var(--dividerColor);
}

.update_actions i {
    cursor: pointer;
}

.update_actions_button {
    padding: .3rem 1rem;
    background-color: var(--primaryColor);
    width: fit-content;
    color: white;
    border-radius: .2rem;
    font-weight: 600;
    letter-spacing: normal;
}

.update_building_form {
    width: 100% !important;
    height: 100%;
    padding: .7rem;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
}

.update_building_form form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.update_input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.update_input h4 {
    font-weight: 500;
}

@keyframes position {
    from {
        transform: translateX(0);
        right: -100%;
    }

    to {
        transform: translateX(0);
        right: 0;
    }
}

.enable_house_inputs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: .5rem;
}

.enable_house_inputs_item {
    /* width: 100%; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: .5rem;
}

@media screen and (max-width : 920px) {

    .update_houses_screen {
        display: flex;
        flex-direction: column;
        grid-gap: .5rem;
        overflow: auto;
    }

    .update_houses_screen_left,
    .update_houses_screen_right {
        width: 100%;
    }

    .update_houses_screen_left,.update_houses_screen_right {
        height: fit-content;
        overflow: unset;
    }

}