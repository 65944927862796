
.tour_site_card{
    width: 18rem;
    height: 18rem;
    background-color: var(--cardColor);
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: .5rem;
    position: relative;
}

.tour_site_card_type_indic{
    position: absolute;
    top: 0;
    z-index: 12;
    padding: .5rem;
    background-color: var(--primaryColor);
    border-bottom-right-radius: 1rem;
}

.tour_site_card_type_indic h6{
    color: var(--textColorOnColoredCard);
    font-size: small;
    font-weight: 700;
}

.tour_site_card_details_price_like{
    justify-content: space-between;
    column-gap: .5rem;
    align-items: flex-start;
}

.tour_site_card_details_price_like i{
    font-size: 1rem;
    color: var(--cardColor);
}

.tour_site_card_details_price_like i:hover{
    cursor: pointer;
}

.tour_site_card_details_star{
    justify-content: flex-start;
}

.tour_site_card_details_star i{
    font-size: smaller;
    color: var(--primaryColor);
}


.tour_site_card_details{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    grid-gap: .4rem;
    height: 50%;
}

.tour_site_card_details{
    cursor: pointer;
}

.tour_site_card_details_more{
    justify-content: flex-start;
    align-items: center;
    grid-gap: .3rem;
}


.tour_services{
    width: 100%;
}

.working_days_displayer{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    column-gap: .5rem;
}

.working_days_displayer_item{
    width: 4rem;
    min-width: 4rem;
    background-color: var(--cardColor);
    text-align: center;
    color: white;
    cursor: pointer;
    padding: .2rem;
    border-radius: .2rem;
}

.working_days_displayer_choosen{
    background-color: var(--primaryColor);
}

.tour_site_pricing{
    width: 100%;
}
.tour_site_pricing_header{
    display: flex;
    align-items: center;
    column-gap: .5rem;
}

.tour_site_pricing_header i{
    cursor: pointer;
}
