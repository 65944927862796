.profile_page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  column-gap: 0.6rem;
  overflow: hidden;
}

.profile_page::-webkit-scrollbar {
  width: 0;
}

.profile_page_left {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  width: 26rem;
  min-width: 26rem;
  max-width: 26rem;
  height: 100%;
  overflow: auto;
  max-height: 100%;
}

.profile_page_left_profile {
  width: 100%;
  background-color: var(--cardColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 0.4rem;
  row-gap: 0.5rem;
}

.name_verified {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  column-gap: 0.5rem;
  padding: 0rem 0.5rem;
}

.name_verified p {
  color: var(--subtitleColor);
  font-size: smaller;
}

.name_verified i {
  color: var(--primaryColor);
}

.name_verified h2 {
  text-align: center;
}

.profile_page_left .img_profil_container {
  /* background-color: var(--bgColor); */
  width: 8rem;
  height: 8rem;
}
.profile_page_left .img_profil_container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid var(--primaryColor);
  object-fit: cover;
}

.profile_page_right {
  width: 100%;
  height: 100%;
  background-color: var(--cardColor);
  padding: 1rem;
}

.profile_page_left_loading {
  background-color: var(--cardColor);
  padding: 1rem;
}

.profile_page_left_profile_stat {
  display: flex;
  width: 100%;
  /* justify-content: space-around; */
  background-color: var(--cardColor);
}

.profile_page_left_profile_stat_item {
  padding: 0.5rem 0rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-right: 0.1rem solid var(--dividerColor);
  column-gap: 0.5rem;
}

.profile_page_left_profile_stat div:last-child {
  border-right: none;
}

.dis_users {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.contact_informations_hidden {
  display: none;
}

.user_identification_informations span {
  display: none;
}
.main_profile_container_user_information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 920px) {
  .profile_page {
    display: flex;
    flex-direction: column;
  }

  .profile_page_left {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-width: 100%;
    max-width: 100%;
  }
  .profile_page_left_profile {
    height: 9rem;
    display: flex;
    flex-direction: column;
    padding: 0rem 1rem;
  }

  .main_profile_container_user_information {
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    gap: 0.5rem;
  }
  .main_profile_container_user_information .img_profil_container {
    width: 5rem;
    height: 5rem;
  }
  .main_profile_container_user_information .mobile_view {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.2rem;
    justify-content: space-around;
  }

  .user_identification_informations h3 {
    display: flex;
    justify-content: flex-start;
  }
  .user_identification_informations p {
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }
  .user_identification_informations span {
    display: block;
  }

  .Supplementary_information {
    display: none;
  }

  .profile_page .main_displayer {
    display: block;
  }
  .contact_informations_hidden {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    gap: 0.5rem;
  }
  .contact_informations_hidden div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .contact_informations_hidden a {
    background-color: var(--primaryColor);
    width: 1.8rem;
    height: 1.8rem;
    display: grid;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .contact_informations_hidden a i {
    font-size: 0.8rem;
    color: #fff;
  }
  .contact_informations_hidden span {
    background-color: var(--primaryColor);
    width: 1.8rem;
    height: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .contact_informations_hidden span i {
    font-size: 0.7rem;
    color: #fff;
    cursor: pointer;
  }
}
