.hotel_card_type_indic{
    position: absolute;
    top: 0;
    z-index: 12;
    padding: .5rem;
    background-color: var(--primaryColor);
    border-bottom-right-radius: 1rem;
}

.hotel_card_type_indic h6{
    color: var(--textColorOnColoredCard);
    font-size: small;
    font-weight: 700;
}

.hotel_card_details_price_like{
    justify-content: space-between;
}

.hotel_card_details_price_like i{
    font-size: 2rem;
    color: var(--dividerColor);
}

.hotel_card_details_price_like i:hover{
    cursor: pointer;
}

.hotel_card_details_star{
    justify-content: space-between;
    width: 100%;

}

.hotel_card_details_star i{
    font-size: smaller;
    color: var(--primaryColor);
}


.hotel_card_details{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    grid-gap: .4rem;
}

.hotel_card_details:hover{
    cursor: pointer;
}

.hotel_card_details_more{
    justify-content: flex-start;
    align-items: center;
    grid-gap: .3rem;
}

.details_actions_separator{
    column-gap: .5rem;
}

.hotel_details_screen_displayer_right_header{
    align-items: flex-start;
}


.hotel_room{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .5rem;
}

.hotel_room_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hotels_rooms_types{
    border-radius : .4rem;
    border : .1rem solid var(--dividerColor);
    padding : .5rem;
}

.hotels_rooms_types_names{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: .5rem;
    flex-wrap: wrap;
    align-items: center;
    /* border-top: .1rem solid var(--dividerColor); */
    padding: .5rem 0rem;
}

.hotels_rooms_types_names_items{
    color: var(--subtitleColor);
    font-weight: 500;
    cursor: pointer;
}

#hotels_rooms_types_names_items_selected{
    color: var(--textColor);
    font-weight: 800;
}