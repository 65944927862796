.notifier {
  width: 100%;
  padding: 0.5rem 3rem;
  border-radius: 0.3rem;
}

.notifier h6 {
  font-weight: 500;
  font-size: smaller;
  text-align: center;
}

.notifier.error {
  background-color: rgb(243, 184, 184);
}
.notifier.error h6 {
  color: red;
}

.notifier.success {
  background-color: rgb(184, 243, 187);
}
.notifier.success h6 {
  color: green;
}

.notifier.info {
  background-color: rgb(184, 243, 228);
}
.notifier.info h6 {
  color: var(--primaryColor);
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.09287464985994398) 0%,
    rgba(0, 5, 5, 0.09007352941176472) 49%,
    rgba(0, 0, 0, 0.09007352941176472) 100%
  );
  padding: 2rem;
}

.popup_container {
  width: clamp(12rem, -6rem + 96vw, 30rem);
  background-color: var(--cardColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  border-radius: 0.5rem;
  animation: 0.3s linear 0s both alternate Pop_up;
}
.popup_container img {
  width: clamp(8rem, 4.0006rem + 21.33vw, 12rem);
  height: clamp(8rem, 4.0006rem + 21.33vw, 12rem);
}
.popup_container p {
  padding: 0 0.5rem;
  font-size: clamp(0.8rem, 0.4294rem + 1.52vw, 1rem);
}

@keyframes Pop_up {
  from {
    scale: 0;
  }
  90% {
    scale: 1.2;
  }
  to {
    scale: 1;
  }
}

.close_popup {
  border-top: solid 0.1rem var(--bgColor);
  width: 100%;
  padding: 0.2rem 0rem;
  color: var(--textColor);
  margin-top: 1rem;
  /* border-radius: 0.3rem; */
  font-weight: 500;
  color: var(--primaryColor);
  font-size: clamp(0.8rem, 0.4294rem + 1.52vw, 1rem);
  cursor: pointer;
}
