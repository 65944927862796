.comments_displayer,
.comments_displayer_comment_card {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: .5rem;
    background-color: var(--bgColor);
    padding: .5rem;
    border-radius: .3rem;
}


.comments_displayer_form {
    display: flex;
    grid-gap: .5rem;
    align-items: center;
    justify-content: space-between;
}

.comments_displayer_action_p {
    font-size: smaller;
    font-weight: bold;
    cursor: pointer;
    text-align: end;
}

.comments_displayer_comment_card {
    background-color: var(--cardColor);
    border-radius: .3rem;
}

.comments_displayer_comment_card_header {
    justify-content: flex-start;
    align-items: center;
    grid-gap: .7rem;
}

.comments_displayer_comment_card_header img {
    height: 2.5rem;
    width: 2.5rem;
    min-height: 2.5rem;
    min-width: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
    background-color: var(--bgColor);
    border: .1rem solid var(--primaryColor);
}

.comments_displayer_comment_card_header_footer {
    justify-content: space-between;
}

.comments_displayer_comment_card_comment {
    font-size: .9rem;
}

#delete_comment_button {
    cursor: pointer;
    display: none;
}

.comments_displayer_loading {
    padding: 1rem .5rem .5rem .5rem;
    border-radius: .3rem;
    background-color: var(--cardColor);
}

.comments_displayer_comment_card_comment_date {
    color: var(--subtitleColor);
    font-size: .8rem;
}

.view_over_comments {
    height: calc(((100% - 2rem)));
    width: calc(((80% - 2rem)));
    overflow: hidden;
    position: absolute;
    z-index: 100;
    background-color: var(--cardColor);
    border-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: .5rem;
}

.view_over_comments_header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: .5rem;
    border-bottom: .1rem solid var(--dividerColor);
}

.comments_displayer_comment_card {
    border-radius: 0rem;
    padding: .8rem .5rem;
    margin-bottom: .5rem;
}

.comments_displayer_comment_card:hover #delete_comment_button{
    display: flex;
}

@media screen and (max-width : 425px) {

    .view_over_comments {
        width: 90%;
    }
}