.rental_form{
    width: 100%;
    /* background-color: var(--cardColor); */
}


.row_inputs{
    align-items: center;
}

.update-flex-actions i{
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
}

.horizontal_form{
    width: 100%;
    display: flex;
    grid-gap: .5rem;
}

.horizontal_form fieldset{
    width: 100%;
    display: flex;
    grid-gap: .5rem;
    align-items: flex-end;
}
