.Home_page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--bgColor);
}

.dif_modal {
  width: 30rem;
  min-width: 30rem;
  background-color: var(--bgColor);
  border-radius: .4rem;
  max-height: 90%;
}

.Home_page>.notifier {
  display: none;
}

.footer {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: .6rem 1rem;
  background-color: var(--cardColor);
  border-top: 1px solid var(--dividerColor);
}

.powered {
  display: flex;
  column-gap: .4rem;
}

.powered a {
  text-decoration: none;
  color: var(--primaryColor);
}

.footer>.Menu_container {
  display: none;
}

@media screen and (max-width : 920px) {
  nav>.Menu_container {
    display: none;
  }

  /* .Nav_action>.profil_container {
    display: none;
  } */

  .footer>p {
    display: none;
  }

  .powered {
    display: none;
  }

  .footer {
    justify-content: center;
    padding: .6rem 1rem;
  }

  .footer>.Menu_container {
    gap: 0rem;
    display: flex !important;
    width: 100%;
    justify-content: space-around;
  }

  .footer>.Menu_container>li>a {
    display: flex;
    align-items: baseline;
    transition: all .5s ease-in-out;
  }

  .footer>.Menu_container>li>a>i {
    font-size: 1.3rem;
  }

  .footer>.Menu_container>li>a>h4 {
    display: none;
  }

  .footer>.Menu_container>li>.active>h4 {
    display: block;
  }

  .Home_page>.notifier {
    display: block;
  }

}

@media screen and (max-width : 600px) {
  .dif_modal {
    width: 90%;
    min-width: 90%;
    max-height: 90%;
    position: relative;
  }

  .search_displayer_form_header{
    padding: 0rem 0rem;
  }


}

@media screen and (max-width : 425px) {
  .footer>.Menu_container {
    justify-content: space-between;
  }

  .Logo>#logo {
    font-size: 1.2rem
  }

  .action_button {
    padding: .5rem 1rem;
    border-radius: .3rem;
    justify-content: center;
    grid-gap: .5rem;
    align-items: center;
  }

}