.update_bedrooms{
    background-color: var(--bgColor);
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: .5rem;
}

.update_bedrooms_item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 1rem;
    padding: 0rem 0rem;
}

.update_bedrooms_item_body{
    background-color: var(--cardColor);
    width: fit-content;
    border: none;
}

.update_bedrooms_item_body_new{
    padding: 0rem .5rem;
}

.add_new_room{
    background-color: var(--primaryColor);
    color: white;
    padding: .4rem .8rem;
    cursor: pointer;
    border-radius: .2rem;
    cursor: pointer;
}

#spinner_loader{
    color: var(--primaryColor);
}

.update_bedrooms_item i{
    cursor: pointer;
}

@media screen and (max-width : 500px) {
    .enable_house_inputs{
        justify-content: space-around;
    }
    .enable_house_inputs_item{
        flex-direction: column;
        align-items: flex-start;
    }
}