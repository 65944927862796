.report_house_container{
    background-color: var(--bgColor);
    width: 30rem;
    min-width: 200px;
    height: fit-content;
    padding : .7rem 1rem;
    border-radius: .7rem;
}

#report_house_container{
    padding: .5rem 1rem;
}
.message_cotainer{
    text-align: justify;
}
.message_cotainer h4 {
    padding: .5rem 0;
}
.message_cotainer p{
    font-size: .9rem;
}
.message_cotainer strong{
    color: var(--primaryColor);
}
.message_cotainer p a{
    color:var(--primaryColor);
}
.report_form{
    width: 100%;
}
.report_form > button {
    width: 100%;
}