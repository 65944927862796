.entity_displayer{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    transition: fadeEffect 1.3s;
}
.no_data_was_found{
    width: 100%;
    height: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(25%);
    gap: 2rem;
}.no_data_was_found img {
    width: 50%;
    height: 5rem;
}.expandeIMg{
    transform: translateY(35%);
}.expandeIMg img{
    width: clamp(10rem, 2.5000rem + 40.000vw, 20rem);
    height: clamp(5rem, 1.2500rem + 20.0000vw, 10rem);
}.no_data_was_found i{
    cursor: pointer;
    font-size: clamp(0.9rem, 0.3750rem + 3.0000vw, 1.5rem)
}.not_login_text{
    padding: 1rem;
    text-align: center;
    font-size: clamp(0.7rem, 0.4375rem + 1.5000vw, 1rem);
}