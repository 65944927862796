.file_uploader_input {
    width: 100%;
    max-width: 100%;
    height: fit-content;
    /* overflow-x: hidden; */
}

.input_like_div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--cardColor);
    border: none;
    outline: none;
    width: 100%;
    height: 2.4rem;
    padding: 10px;
    margin: 4px 0px;
    border: 1px solid var(--cardColor);
    box-sizing: border-box;
    color: var(--textColor);
    border-radius: 5px;
}

.file_uploader_input_hint_style {
    font-style: normal;
    font-weight: normal;
    font-size: small;
    text-align: start;
    margin: 0px;
    color: var(--subtitleColor);
    max-width: 100%;
    text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 100%;
}

.file_uplaoder_input_field_label {
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 4px 0px;
    max-width: 100%;
}

.file_uplaoder_input_field_label:hover {
    cursor: pointer;
}

.file_uploader_input_not_empty {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: fit-content;
    background-color: var(--cardColor);
    width: 100%;
    max-width: 100%;
}

.file_uploader_input_not_empty i {
    color: var(--primaryColor);
}

.file_uploader_input_not_empty_item {
    display: flex;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.01rem solid var(--dividerColor);
    background-color: red;
    padding: .2rem 0rem;
}


.images_lu i {
    cursor: pointer;
    color: var(--subtitleColor);
}

.images_lu p{
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: small;
}

.images_lu{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: .5rem;
    overflow: hidden;
    border-bottom: .1rem solid var(--dividerColor);
}

#upload_indicator_circle-exclamation {
    color: red;
}

#upload_indicator_circle-check {
    color: rgb(103, 216, 109);
}

.file_uploader_input_upload_indicator {
    grid-gap: .4rem;
    align-items: center;
}

.file_uploader_input_upload_indicator h6 {
    font-weight: 700;
    font-size: x-small;
}

.file_uploader_input_not_empty_pl_h {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: .5rem;
    max-width: 100%;

}

.file_uploader_input_not_empty_pl_h p {
    min-width: 5rem;
}

.file_uploader_input_not_empty_pl_h h6 {
    font-weight: bold;
}

.file_uploader_input_not_empty_pl_h h6:hover {
    cursor: pointer;
    color: var(--primaryColor);
    text-decoration: underline;

}