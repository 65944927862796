.main_displayer_tabs{
    padding: 1rem 0 .3rem 0;
    grid-gap: 1rem;
}

.main_displayer_tabs_card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 1rem 0rem 0rem;
    font-weight: 100;
    border-right: 1px solid var(--dividerColor);
 
}

.main_displayer_tabs_card:hover{
    cursor: pointer;
}

.main_displayer_tabs_card_last{
    border-right:none
}

.main_displayer_tabs_card i{
    font-size: 1.2rem;
    color: var(--subtitleColor);
}
.main_displayer_tabs_card h3{
    font-weight: 500;
    font-size: small;
    white-space: nowrap;
    color: var(--subtitleColor);
}

.main_displayer_tabs_card_selected h3, .main_displayer_tabs_card_selected i{
    color: var(--primaryColor);
}