#publish_displayer {
    background-color: var(--bgColor);
    display: flex;
    flex-direction: column;
    border-radius: .5rem;
    overflow: hidden;
    padding: 0rem 0rem 1rem 0rem;
    max-height: 80%;
    min-height: 80%;
}

.publish_modal {
    height: fit-content;
    display: flex;
    flex-direction: column;
    height: 80%;
    min-height: 80%;
    max-height: 80%;
    overflow: hidden;
}

.publish_displayer_scroll_part {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: .5rem;
    overflow-y: auto;
    padding: 1rem;
}

.publish_displayer_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: .1rem solid var(--dividerColor);
    padding: .5rem;
}

.publish_displayer_scroll_part_form_holder {
    width: 100%;
    height: 100%;
}

.form_for_publish {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    overflow: auto;
    padding: .5rem;
    height: fit-content;
}

.form_for_publish form button{
    width: 100%;
}

.form_for_publish form{
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    /* row-gap: .1rem; */
}