.update_user_informations {
    background-color: var(--bgColor);
    width: 45rem;
    padding: 1rem;
    border-radius: .5rem;
}

.update_user_informations_header{
    padding: .5rem 0rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.update_user_informations_header h2{
    font-size: clamp(1.2rem, 0.9002rem + 1.3700vw, 1.5rem);
    /* font-size: 1.5rem; */
}
.change_password_up{
    padding: 1rem;
}
.update_user_informations_body{
    width: 100%;
    display: flex;
    grid-gap: 1rem;
    overflow: hidden;
}

.update_user_informations_left{
    width: 15rem;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.update_user_informations_left img{
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    min-width: 10rem;
    min-height: 10rem;
    object-fit: cover;
    border: .15rem solid var(--primaryColor);
    background-color: var(--cardColor);
}

.update_user_informations_right{
    width: 100%;
    height: 100%;
    overflow: auto;
}

.update_user_informations_right button{
    width: 100%;
}

.changeProfilePhotoUpdateLabel{
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
    font-size: small;
    font-weight: 600;
    color: var(--primaryColor);
    cursor: pointer;
}

@media screen and (max-width : 920px){

    .update_user_informations{
        width: 90%;
        overflow-y: auto;
    }

    .update_user_informations_body{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* overflow-y: auto; */
    }
  }