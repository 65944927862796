.search_users {
    background-color: var(--cardColor);
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;
    width: 50%;
}

.search_users_rideau {
    align-items: flex-start;
    padding-top: 2rem;
}

.search_users_body_header {
    display: flex;
    width: 100%;
    align-items: center;
    column-gap: .5rem;
    padding: .5rem;
    background-color: var(--bgColor);
}


.search_users_body_results,.search_users_body_loading {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* background-color: red; */
    padding: .5rem 1rem;
}

.search_users_body{
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.search_users_body_bottom{
    overflow: auto;
    height: 100%;
}

.search_users_card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: .1rem solid var(--dividerColor);
    padding: .5rem 0rem;
}