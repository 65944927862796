
.themeChangerButton{
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
}

.themeChangerButton i{
    /* font-size: 1rem; */
    color: var(--primaryColor);
}

.themeChangerButton i:hover{
    cursor: pointer;
}

.small_menu_container  .expended{
    justify-content: space-between;
    align-items: center;
    padding-left: .1rem;
    margin-top: 1rem;
    width: 100%;
    cursor: pointer;
    font-size: .9rem;
    font-weight: 500;
} 

.onWideArea{
    display: flex;
    width: 100%;
    height: 100%;
    gap: 1rem;
    font-size: .9rem;
    font-weight: 500;

}