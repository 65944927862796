.pop_up_input{
    display: none;
}

.pop_up_input_displayed{
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: flex-end;
    width: 95%;
    background-color: var(--cardColor);
    top: 0;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 2.95px 30.6px;
    max-height: 20rem;
    overflow: auto;
    z-index: 1200;
    border-radius: .3rem;
}

.pop_up_input_expanded{
    height: 15rem; 
}

.pop_up_input_body_holder{
    height: 100%;
    width: 100%;
    padding: 0;
}

.pop_up_input_actions{
    width: 100%;
    grid-gap: 1rem;
    justify-content: flex-end;
    align-items: center;
    padding: 0rem .5rem;
}

.pop_up_input_actions_item{
    font-size: small;
    color: var(--primaryColor);
    padding: .5rem 0rem;
}