
.loader{
    border: .3rem solid var(--dividerColor);
    border-top: .3rem solid var(--primaryColor) ;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    animation: spin .4s linear infinite;
}

.full_space_loader{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes spin {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}