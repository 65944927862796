.cards_displayer_wrapper {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    grid-auto-flow: row;
    grid-auto-rows: 20rem;
    grid-gap: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding: .5rem .5rem;
    position: relative;
    background-color: var(--bgColor);
}

.view_type_button_holder {
    background-color: red;
    align-items: center;
    justify-content: center;
    display: flex;
    /* width: 100%; */
    width: fit-content;
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 50;
    min-width: fit-content;
    padding: 1.5rem 0rem;
}

#view_type_button_holder_div{
    background-color: black;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    z-index: 500;
}


/* .view_type_button_holder div:nth-child(1) h6{
    color: black;
} */

.view_type_button h5 {
    color: var(--textColorOnColoredCard);
} 