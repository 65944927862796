.main_displayer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    /* background-color: var(--cardColor); */
    border-radius: .4rem;
}



.main_displayer_currentScreen{
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.main_displayer_currentScreen::-webkit-scrollbar{
    width: 0;
}
