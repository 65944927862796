.details_modal_body_holder{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: .5rem;
    grid-gap: .2rem;
    --webkit-animation-name: sliderFromBottom;
    --webkit-animation-duration: .3s;
    animation-name: sliderFromBottom;
    animation-duration: .3s;
}

.details_modal_body_holder_title{
    display: flex;
    justify-content: flex-end;
    padding: .5rem;
    background-color: var(--cardColor);
    border-bottom: .1rem solid var(--dividerColor);
}
.details_modal_body{
    /* padding: 2rem; */
    padding: 4rem 0rem 0rem 0rem;
}

.details_modal_body_holder_title_close_button{
    height: 2rem;
    width: 2rem;
    padding: .2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primaryColor);
    border-radius: 50%;
}

.details_modal_body_holder_title_close_button i{
    color: var(--textColorOnColoredCard);
    font-size: large;
}

.details_modal_body_holder_title_close_button:hover{
    cursor: pointer;
}

.details_modal_body_holder_body{
    overflow: auto;
    height: 100%;
    width: 100%;
    background-color: var(--cardColor);
}

@keyframes sliderFromBottom{
    0%{
        margin-top: 100%;
    }
    100%{
        margin-top: 0;
    }
}