.main_search_displayer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}


.main_search_displayer_currentScreen {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    position: relative;
}

.main_search_displayer_currentScreen_right,
.main_search_displayer_currentScreen_left {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .5rem;
}

.main_search_displayer_currentScreen_left {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    /* grid-gap: .5rem; */
    overflow: hidden;
}

.main_search_displayer_currentScreen_right {
    overflow: hidden;
    height: 100%;
    position: absolute;
    background-color: var(--bgColor);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: none;
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
    right: 0;
    width: 0;
    max-width: 0;
    padding: .5rem;
    /* max-width: 0; */
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
    z-index: 1500;
    max-height: 100%;
}

.main_search_displayer_currentScreen_right_form{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
    grid-gap: .5rem;
    /* padding: 0rem .5rem; */
    padding-right: .5rem;
}

.main_search_displayer_currentScreen_right_form button{
    width: 100%;
}

.main_search_displayer_currentScreen_left_form {
    width: 100%;
}

.row_inputs_search {
    width: 100%;
    column-gap: .5rem;
}

#row_inputs_search_h6{
    padding: .5rem 1rem;
    color: var(--textColor);
    font-size: .7rem;
    align-items: center;
    display: flex;
    cursor: pointer;
    border-radius: .18rem;
    border: .1rem solid var(--textColor);
}

.row_inputs_search h6{
    background-color: inherit;
    width: fit-content;
    overflow: hidden;
    user-select: none;
    white-space: nowrap;
    border: .1rem solid var(--textColor);
    font-weight: 500;
    color: var(--textColor);
    font-size: .9rem;
    align-items: center;
    display: flex;
    height: 2.4rem;
    text-align: center;
    vertical-align: middle;
    padding: 0rem 1rem;
    cursor: pointer;
    border-radius: .18rem;
}

.search_displayer_form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    position: relative;
}

.search_displayer_form_header {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    column-gap: .5rem;
}

#search_icons_dis{
    display: none;
}

#search_icons_dis i{
    cursor: pointer;
    font-size: 1.2rem;
}

#search_icons_dis, #search_buttons_dis{
    column-gap: .5rem;
}

.main_search_displayer_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    column-gap: 15%;
}

@media screen and (max-width : 920px) {

    .main_search_displayer_header{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .search_displayer_form_header{
      padding: .5rem 0rem;
    }

    #search_buttons_dis{
        display: none !important;

    }

    #search_icons_dis{
        display: flex !important;
    }
  }

.search_displayer_form_header_filters {
    position: absolute;
}

.more_filter_search {
    width: 100%;
    max-height: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.2s ease;
}

.row_inputs {
    grid-gap: .5rem;
    width: 100%;
}

.row_inputs input{
    outline: none;

}

.search_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* grid-gap: .5rem; */
}


.main_search_displayer_currentScreen_right_sesults {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow: auto;
    border-radius: 2rem;
    padding: 0 .8rem;
}

.main_search_displayer_currentScreen_left input[type=checkbox] {
    width: 1.2rem;
    height: 1.2rem;
    border: none;
}

.main_search_displayer_currentScreen_left_amenities {
    display: flex;
    flex-wrap: wrap;
    grid-gap: .5rem;
}

.main_search_displayer_currentScreen_left_amenities_item {
    grid-gap: .3rem;
}

.main_search_displayer_currentScreen_left_amenities_item label {
    font-size: small;
    border: none;
}

.search_result_displayer {
    width: 100%;
    height: 100%;
    background-color: red;
    padding: .5rem;
}