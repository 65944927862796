.row_value_displayer{
    width: 100%;
    padding: .25rem 0rem;
    border-top: 1px solid var(--dividerColor);
    justify-content: space-between;
    align-items: flex-start;
    grid-gap: 1rem;
}

.row_value_displayer h5{
    font-size: medium;
    font-weight: 600;
}

.row_value_displayer h6{
    font-size: medium;
    font-weight: 500;
    color: var(--subtitleColor);
    text-align: end;
}