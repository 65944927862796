.my_account_main_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    background-color: var(--bgColor);
    padding: .5rem;
}

.my_account_container {
    width: 100%;
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    display: flex;
    column-gap: .5rem;
    position: relative;
}

.profil_account_container {
    height: 100%;
    min-height: 100%;
    border-radius: .3rem;
    overflow: auto;
    padding-right: .5rem;
    width: 26rem;
    min-width: 26rem;
    max-width: 26rem;
}

.main_container_account {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.my_account_hidden {
    display: none;
}

.img_container_actions {
    position: absolute;
    right: 0;
    display: flex;
    column-gap: .5rem;
}

.profile_account_header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    background-color: var(--cardColor);
    padding: 1rem .5rem;
    border-radius: .4rem;
}

.img_container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
}

.img_container>img {
    border: 2px solid var(--primaryColor);
    border-radius: 50%;
    object-fit: cover;
}

.Modify_icon {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--primaryColor);
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.Modify_icon>i {
    color: var(--textColorOnColoredCard);
}

.subscription_type {
    position: absolute;
    top: 3rem;
    right: 0rem;
    background-color: var(--diamondColor);
}

.subscription_type>.subscription_title {
    position: absolute;
    text-align: center;
    left: -2.8rem;
    bottom: -1.5rem;
    width: 8rem;
    padding: .3rem .4rem;
    border-radius: .4rem;
    color: #696969;
    display: none;
    transition: 0.10s 1s ease;
    z-index: 12;
}

.subscription_title::before {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: -7px;
    left: 58px;
    border-radius: .2rem;
    transform: rotate(40deg);
    z-index: 0;
}

.subscription_type:hover .subscription_title {
    display: block;
}

.add_publication {
    position: absolute;
    top: 6rem;
    background-color: var(--primaryColor);
}

.user_information_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0rem;
}

.profile_account_main {
    width: 100%;
    margin-top: .5rem;
}

.tiles_single_content {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--cardColor);
    border-radius: .4rem;
    padding: .6rem .9rem;
    column-gap: .7rem;
    margin-bottom: .5rem;
    cursor: pointer;
}

.title_and_details_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: 0rem .2rem;
    font-size: .7rem;
}

.title_and_details_container h4 {
    font-size: .9rem;
    font-weight: 500;
}

#inside_icon {
    font-size: 1rem;
    color: var(--primaryColor);
    height: 100%;
    width: 1rem;
    display: flex;
    justify-content: center;
}

#inside_icon_logout {
    color: var(--textColorOnColoredCard);
    font-size: 1.1rem;
}

.flex_tiles {
    display: flex;
    width: 100%;
    column-gap: .7rem;
    padding: .5rem 0rem;

}

.display_row {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.logout {
    background-color: #ef3038;
    /* margin: 0rem; */
    color: var(--textColorOnColoredCard);
    cursor: pointer;

}.logout:hover{
    color: var(--textColorOnColoredCard);
}

.logout>i {
    color: var(--textColorOnColoredCard);
}

.logout>.title_and_details_container>h4 {
    color: var(--textColorOnColoredCard);
}

.content_divider {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.house_container {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.parent_small_nav_bar {
    padding: 1.5rem 1.5rem 0rem 0rem;
}

.small_nav_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .3rem 1rem;
    background-color: var(--cardColor);
    border-radius: .3rem;
}

#incon_nav_bar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primaryColor);
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}

#incon_nav_bar i {
    color: var(--cardColor);
}

.my_account_not_created_yet {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--bgColor);
    padding: 0rem 1rem;
    gap: 2rem;
}


.my_account_not_created_yet img{
    height: 10rem;
    width: 50%;
    min-width: 50%;
    /* min-height: 50%; */
}

.my_account_not_created_yet p,
.my_account_not_created_yet i {
    font-style: normal;
    font-size: clamp(0.8rem, 0.6500rem + 0.8000vw, 1rem);
    cursor: pointer;
    text-align: center;
}
.not_login_text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inline_auth {
    text-decoration: underline;
    color: var(--primaryColor);
    font-size: clamp(0.8rem, 0.6500rem + 0.8000vw, 1rem);
    cursor: pointer;
}

.contact_user {
    display: flex;
    justify-content: center;
    column-gap: .5rem;
}

.contact_user span {
    display: flex;
    column-gap: .3rem;
}

#email {
    font-weight: 550;
}

#usertype {
    padding: 0;
    font-size: small;
    color: var(--subtitleColor);
}

.simplified_user_card {
    display: none;
}

.simplified_user_card_left {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
}

.simplified_user_card img {
    height: 3rem;
    width: 3rem;
    min-width: 3rem;
    min-height: 3rem;
    border-radius: 50%;
    object-fit: cover;
}

.simplified_user_card i {
    font-size: larger;
    color: var(--primaryColor);
    cursor: pointer;
}

.profil_account_container #cross_container:nth-child(2){
    display: none;
}



@media screen and (max-width : 920px) {

    .my_account_hidden {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        height: 100%;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s ease;
        padding: .5rem;
        max-height: 100%;
        z-index: 1000;
        overflow: auto;
    }

    .profile_account_header {
        width: 100%;
    }

    .profil_account_container {
        display: none;
    }

    .profil_account_container2 {
        width: 100%;
    }

    .simplified_user_card {
        margin-top: .5rem;
        width: 100%;
        background-color: var(--cardColor);
        padding: .5rem 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 1rem;
        border-radius: .4rem;
        align-items: center;
    }

    .my_account_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        row-gap: 1rem;
        overflow: hidden;
    }
}

/* @media screen and (max-width : 600px) {
    .my_account_hidden {
        width: 90%;
        min-width: 90%;
    }
} */