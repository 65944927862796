.checkbox_input_holder{
    display: flex;
    flex-direction: row;
    grid-gap: .2rem;
    align-items: center;
    justify-content: center;
    padding: .3rem 0rem;
}

.checkbox_input_holder input[type=checkbox]{
    width: 1rem;
    height: 1rem;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.checkbox_input_holder_disabled_holder{
    display: flex;
    flex-direction: row;
    padding: 0rem;
    margin: 0rem;
    grid-gap: .5rem;
    height: fit-content;
}

.bedroom_input_new{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: fit-content;
    width: 100%;
    max-width: 100%;
    margin: 4px 0px;
    border-radius: 5px;
    grid-gap: 0rem;
    overflow: hidden;
    background-color: var(--cardColor);
}

.bedRoom_input{
    width: 100%;
    max-width: 100%;
    margin: 4px 0px;
    border-radius: 5px;
    grid-gap: 0rem;

    background-color: var(--cardColor);
}

.bed_room_heading{
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
}

.bedRoom_input_title{
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 2rem;
    align-items: flex-start;
    padding: .5rem;
    /* overflow: hidden */
}

.bedRoom_input_title p{
    font-size: smaller;
    color: var(--subtitleColor);
}

.bedRoom_input_title input{
    width: 50%;
    border: 1px solid var(--dividerColor);
    height: 2rem;
}

.room_item{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .1rem .5rem;
    border-top: .1rem solid var(--dividerColor);
    column-gap: .5rem;
}