nav {
  grid-area: nav;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.51rem;
  background-color: var(--cardColor);
}

.Logo {
  height: 100%;
  display: flex;
  align-items: center;
}

.Logo a, .Logo a h2 {
  color: var(--primaryColor);
  cursor: pointer;
}

.Menu_container {
  display: flex;
  column-gap: 2rem;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Menu_container > li {
  display: flex;
  cursor: pointer;
  color: var(--subtitleColor);
}

.Menu_container > li > a {
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  transition: all 1s ease-in-out;
}

.Menu_container > li > a > h4 {
  color: gray;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
}

.Menu_container .active > h4,
.Menu_container .active > i {
  color: var(--primaryColor);
}

.Nav_action {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1em;
}

.Nav_action i {
  cursor: pointer;
  font-size: 1rem;
  color: var(--primaryColor);
}

.profil_container {
  position: relative;
  display: flex;
  align-items: center;
  gap: .8rem;
  padding: 0rem 0.5rem;
  border: .1rem solid var(--dividerColor);
  border-radius: 0.3rem;
  height: 100%;
  cursor: pointer;
}

.profil_container:hover {
  box-shadow: 4px 9px 27px -20px rgba(0, 0, 0, 0.73);
  -webkit-box-shadow: 4px 9px 27px -20px rgba(0, 0, 0, 0.73);
  -moz-box-shadow: 4px 9px 27px -20px rgba(0, 0, 0, 0.73);
}

#active_status {
  position: absolute;
  top: 0.4rem;
  right: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--primaryColor);
  border-radius: 50%;
}

#profil {
  margin-top: 2px;
  font-size: 1.8rem;
  color: var(--subtitleColor);
}

#be_a_publisher {
  font-size: 0.8rem;
  background-color: var(--primaryColor);
  padding: 0.4rem;
  border-radius: 0.3rem;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--cardColor);
  cursor: pointer;
}
.curtain_small {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
}
.small_user_circle {
  display: flex;
  align-items: flex-start;
  width: 1.8rem;
  height: 1.8rem;
}

.small_user_circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: solid 0.1rem var(--bgColor);
  border-radius: 50%;
}

.small_menu_container {
  position: absolute;
  right: 0.5rem;
  margin: auto;
  top: 3.5em;
  width: 15rem;
  height: fit-content;
  padding: 1rem 1.2rem;
  text-align: left;
  background-color: var(--cardColor);
  border-radius: 1rem;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  flex-direction: column;
  row-gap: .7rem;
}

hr {
  margin-top: 12px;
}

#small_title {
  color: white;
}

.small_menu_title {
  display: flex;
  justify-content: center;
  gap: 0.7rem;
  align-items: center;
  color: var(--textColor);
  margin-bottom: 0.5rem;
  border-bottom: solid 1.5px var(--cardColor);
  /* font-size: 1rem; */
}
.small_menu_title span {
  font-size: 0.9rem;
  font-weight: 500;
}
.small_menu_title:hover {
  color: var(--primaryColor);
  cursor: pointer;
}

.small_menu {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
  gap: 0.7rem;
}
.small_menu > i {
  color: var(--primaryColor);
  width: 1rem;
  font-size: 1rem;
}

.small_menu > p {
  list-style: none;
  font-weight: 500;
  font-size: 0.9rem;
  color: var(--textColor);
}

.media_query_tester {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: red;
  display: flex;
  flex-direction: row;
}

.media_query_tester_left,
.media_query_tester_right {
  width: 50%;
  height: 100%;
}

.media_query_tester_left {
  background-color: aquamarine;
}

.media_query_tester_right {
  background-color: orange;
  overflow: auto;
}
.languageDisplayer {
  position: absolute;
  margin: auto;
  right: 12.5rem;
  padding: 0 0.4rem;
  top: 3rem;
  width: 7rem;
}

.active_route h4, .active_route i{
  font-weight: 900;
  color: var(--primaryColor) !important;
}

#publish-on-small-area {
  display: none;
}


@media screen and (max-width: 920px) {

  #publish-on-small-area {
    display: block;
    font-size:x-large;
  }

  #publish-on-wide-area {
    display: none;
  }

  .media_query_tester {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    overflow: auto;
  }

  .media_query_tester_left,
  .media_query_tester_right {
    width: 100%;
  }

  .media_query_tester_left {
    height: 100%;
    min-height: 100%;
  }

  /* .media_query_tester_left {
    background-color: aquamarine;
  } */

  .media_query_tester_right {
    height: fit-content;
    background-color: orange;
    overflow: unset;
  }
  .languageDisplayer{
    right: 6rem;
  }

  .small_user_circle{
    display: none;
  }

  .profil_container{
    border: none;
  }

  .profil_container > i{
    font-size: 1.5rem;
    color: var(--subtitleColor);
    font-weight: 900;
  }
}


