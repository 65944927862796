.forget_pass_kn{
    width: 100%;
    grid-gap: .5rem;
}

#forget_password{
    padding: .5rem 1rem;
}

#forget_password button{
    width: 100%;
    margin-top: .5rem;
}