.multi_select_input{
    cursor: pointer;
    position: relative;
    width: 100%;
}

.multi_select_input_not_empty{
    cursor:default;
}

.multi_select_input_not_empty_title{
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.multi_select_input_not_empty_title i{
    cursor: pointer;
}

.multi_select_input_comp{
    padding: 1rem;
}

.multi_select_input_body{
    width: 100%;
    display: flex;
    grid-gap: .5rem;
    flex-wrap: wrap;
}

.multi_select_input_body_item{
    cursor: pointer;
    padding: 0rem .5rem;
    background-color: var(--dividerColor);
    border-radius: .3rem;
    font-size: small;
}

.multi_select_input_body_item_selected{
    background-color: var(--primaryColor);
    color: white;
}

.multi_select_input_comp_helper{
    font-size: smaller;
    padding: .5rem 0rem;
    color: var(--subtitleColor);
}