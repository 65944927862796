.splash_screen{
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1300;
    background-color: var(--bgColor);
}

.splash_screen_large{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.splash_screen_large img{
    height: 8rem;
    width: 8rem;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

/* .splash_loading{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
} */
