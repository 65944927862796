.modal_rideau {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1300;
    background-color: rgba(0, 0, 0, 0.50);
}

.authentication_container {
    position: relative;
    height: auto;
    padding: 1.2rem;
    background-color: var(--bgColor);
    border-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    overflow-y: auto;
}

.header_login {
    display: flex;
    width: auto;
    justify-content: space-between;
}

.header_login h3 {
    width: 70%;
}

#cross_container {
    position: relative ;
    width: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    height: 2rem;
    background-color: var(--primaryColor);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#cross_container>i {
    /* position : absolute; */
    /* margin: auto; */
    /* transform: translate(11px, 8.5px); */
    color: var(--textColorOnColoredCard);
}

.LR_container {
    padding: 1rem 0rem;
}

.LR_container input:focus {
    outline: 2px solid var(--primaryColor);
}

.footer_authentication {
    width: auto;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: small;
    row-gap: 1rem;
}

.button_on_async {
    background-color: var(--subtitleColor);
    
}

.button_on_async:hover{
    cursor: default;
}



.have_an_account_or_not {
    width: auto;
    display: flex;
    justify-content: center;
    gap: 2px;
}

.have_an_account_or_not h6 {
    color: var(--primaryColor);
    text-decoration: none;
    font-size: small;
    font-weight: 500;
}

.have_an_account_or_not h6:hover {
    cursor: pointer;
}

.registration_form_divider {
    display: flex;
    column-gap: .6rem;
}

.account_type {
    padding: 0 .5rem;
}

.form_input_divider {
    display: flex;
    column-gap: .5rem;
}

.forget_password_h6{
    color: var(--primaryColor);
    font-size: small;
    font-weight: 400;
    cursor: pointer;
}

.Hide_and_show_input{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}.Hide_and_show_input i {
    position: absolute;
    margin: auto;
    right: 1rem;
    top: 0;
    bottom: 0;
    transform: translateY(31%);
    cursor: pointer;
}