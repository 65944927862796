@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    /* user-select: none; */
    zoom: 1;
}


:root {
    --bgColor: rgb(243, 243, 243);
    --cardColor: white;
    --textColor: #212121;
    --textColorOnColoredCard: white;
    --scrollBarBgColor: #d7d7d7;
    --subtitleColor: gray;
    --error: #ef3038;
    --dividerColor: rgb(221, 221, 221);
    --silverColor: #c0c0c0;
    --diamondColor: #b9f2ff;
    --goldColor: #ffd700;
    --primaryColor: #00BFA6;
}

body {
    background-color: var(--bgColor);
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

i {
    color: var(--subtitleColor);
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p {
    color: var(--textColor);
}

::-webkit-scrollbar {
    width: .3rem;
}

::-webkit-scrollbar-track {
    background: var(--scrollBarBgColor);
    /* border-radius: 3px; */
}

::-webkit-scrollbar-thumb {
    background-color: var(--primaryColor);
    /* border-radius: 3px; */
}




input,
select,
input:focus,.multi_select_input_not_empty{
    background-color: var(--cardColor);
    border: none;
    width: 100%;
    height: 2.4rem;
    padding: 10px;
    margin: 4px 0px;
    border: 1px solid var(--cardColor);
    box-sizing: border-box;
    color: var(--textColor);
    outline: none;
    border-radius: 5px;
}

textarea {
    font-style: normal;
    font-weight: normal;
    font-size: small;
    text-align: start;
    vertical-align: middle;
    color: var(--subtitleColor);
    background-color: var(--cardColor);
    border: none;
    width: 100%;
    height: 10rem;
    padding: 10px;
    margin: 4px 0px;
    border: 1px solid var(--cardColor);
    box-sizing: border-box;
    color: var(--textColor);
    outline: none;
    border-radius: 5px;
    resize: none;
}


.checkout_form_stripe {
    width: 100%;
    height: fit-content;
}

.checkout_form_stripe_button {
    width: 100%;
}

fieldset {
    border: none;
    width: 100%;
}

input::placeholder,
textarea::placeholder,
select::placeholder,
option,
.checkbox_input_holder label,
#multi_select_input_title {
    font-style: normal;
    font-weight: normal;
    font-size: small;
    text-align: start;
    vertical-align: middle;
    margin: 0px;
    color: var(--subtitleColor);
}

select,
select:focus {
    color: var(--subtitleColor);
}


.divider {
    height: 5px;
    color: var(--textColor);
    width: 100%;
}

ul>li {
    list-style: none;
    font-weight: 600;
    color: var(--textColor);
}

a {
    text-decoration: none;
}

@keyframes fadeEffect {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.switch{
    position: relative;
    display: inline-block;
    width: 3.5rem;
    min-width: 3.5rem;
    height: 2rem;
    min-height: 2rem;
}

.switch input{
    opacity: 0;
    width: 0;
    height: 0;
}

.slider{
    position: absolute;
    cursor: pointer;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}


.slider::before{
    position: absolute;
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    left: .15rem;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider{
    background-color: var(--primaryColor);
}

input:focus + .slider{
    box-shadow: 0 0 1px var(--primaryColor);
}

input:checked + .slider::before{
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round{
    border-radius: 2rem;
}

.slider.round::before{
    border-radius: 50%;
}

button{
    outline: none;
    border: none;
    padding: .5rem 1rem;
    height: 2.4rem;
    border-radius: .3rem;
    background-color: var(--primaryColor);
    color: var(--textColorOnColoredCard);
    cursor: pointer;
    font-weight : bold;
}

.shine {
    background: var(--cardColor);
    background-image: linear-gradient(to right, var(--cardColor) 0%, var(--bgColor) 20%, var(--cardColor) 40%, var(--cardColor) 100%);
    background-repeat: no-repeat;
    background-size: 800px 100%;
    display: inline-block;
    position: relative;
    animation-duration: .9s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    -webkit-animation-duration: .9s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
    background-color: var(--cardColor);
    border-radius: 1rem;
}


@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

