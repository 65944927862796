.custom_carroussel_body {
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
    overflow: hidden;
    background-color: var(--cardColor);
    position: relative;
}

.custom_carroussel_body:hover .custom_carroussel_scroller_sc {
    position: absolute;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cardColor);
    padding: .4rem;
    border-radius: 100%;
    z-index: 30;
    right: .3rem;
    bottom: 50%;
    /* top: 0; */
}

.custom_carroussel_body:hover .custom_carroussel_scroller_sc_left{
    left: .3rem;
}

.custom_carroussel_body_displayer {
    height: 100%;
    width: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    border-radius: .5rem;
    overflow: hidden;
}

/* .custom_carroussel_body img{
    border: none;
    position: absolute;
    z-index: 1000;
} */


.image_generator {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: .5rem;
}

.custom_carroussel_scroller_sc {
    display: none;
}

.custom_carroussel_scroller_sc:hover {
    cursor: pointer;
}

.custom_carroussel_indicators {
    width: 100%;
    padding: .5rem;
    grid-gap: .3rem;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    flex-wrap: wrap;
}

.custom_carroussel_indicators_ind {
    height: .5rem;
    width: .5rem;
    border-radius: 50%;
    background-color: var(--dividerColor);
}

.custom_carroussel_indicators_ind_selected {
    background-color: var(--primaryColor);
}

.custom_carroussel_body_video {
    height: 100%;
    width: 100%;
    position: relative;
}

.custom_carroussel_body_video video {
    width: 100%;
    height: 100%;
}

.custom_carroussel_body_video_action {
    position: absolute;
    top: 0;
    left: 0;
    margin: .5rem;
}