.details_displayer_screen {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}


.custom_nav_bar {
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;
    border-bottom: .1rem solid var(--dividerColor);
}

.custom_nav_bar h2 {
    color: var(--primaryColor);
}

.custom_nav_bar_right i {
    font-size: 1.4rem;
}

.custom_nav_bar_right {
    grid-gap: 2rem;
}

.custom_nav_bar_right_user {
    padding: .3rem 1rem;
    border: .1rem solid var(--dividerColor);
    grid-gap: .5rem;
    border-radius: .5rem;
}

.details_displayer_screen_displayer {
    height: 100%;
    width: 100%;
    background-color: var(--cardColor);
    display: flex;
    overflow: hidden;
}

.details_displayer_screen_displayer_left,
.details_displayer_screen_displayer_right {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding: .5rem;
    /* background-color: var(--cardColor); */
}

.details_displayer_screen_displayer_left {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.share_to_social_media {
    padding: .5rem 0rem;
    height: auto;
    justify-content: flex-end;
    grid-gap: 1rem;
}

.share_to_social_media h3 {
    font-weight: 500;
    font-size: small;
}

.details_displayer_screen_displayer_left_share_to_logo {
    grid-gap: .5rem;
}

.share_to_social_media i {
    font-size: 1.3rem;
}

.share_to_social_media i:hover {
    cursor: pointer;
}

.details_displayer_screen_displayer_left_likes {
    display: flex;
    justify-content: space-between;
    padding: .5rem 0rem;
}

.details_displayer_screen_displayer_left_likes_left {
    justify-content: center;
    grid-gap: 1rem;
    align-items: center;
}

.details_displayer_screen_displayer_left_likes_left_item {
    justify-content: center;
    grid-gap: .4rem;
    align-items: center;
    display: flex;
}

.details_displayer_screen_displayer_left_likes_left_item i {
    cursor: pointer;
}

.details_displayer_screen_displayer_left_likes_left_item h6 {
    font-size: large;
}

.action_button {
    background-color: var(--primaryColor);
    padding: .5rem 2rem;
    border-radius: .3rem;
    justify-content: center;
    grid-gap: .5rem;
    align-items: center;
}

.action_button i,
.action_button h6 {
    color: var(--textColorOnColoredCard);
    font-size: small;
    font-weight: 600;
}

.action_button:hover {
    cursor: pointer;
}


.details_displayer_screen_displayer_right {
    grid-gap: .5rem;
    overflow: auto;
}

.details_displayer_screen_displayer_right p {
    font-size: .9rem;
}

.details_displayer_screen_displayer_right_price_like {
    justify-content: space-between;
}

.details_displayer_screen_displayer_right_price_like h3 {
    font-size: 1.5rem;
}


.details_displayer_screen_displayer_left_likes_ac i {
    font-size: 2rem;
    padding: 0rem .7rem;
    border-right: .1rem solid var(--dividerColor);
}

.details_displayer_screen_displayer_left_likes_ac i:hover {
    cursor: pointer;
}

#details_displayer_screen_displayer_left_likes_ac_lastI {
    border-right: none;
}


.this_map_holder {
    height: 20rem;
    min-height: 20rem;
    width: 100%;
    border-radius: .3rem;
    overflow: hidden;
}

.this_map_holder_info{
    display: flex;
    flex-direction: column;
    row-gap: .3rem;
    border-radius: .2rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.this_map_holder_info p{
    font-weight:600;
}

.this_map_holder_info_details{
    font-size: medium;
    font-weight: 500;
}

.details_displayer_screen_message_loader {
    height: 100%;
    width: 100%;
    background-color: var(--cardColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.publisher_card {
    width: 100%;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
    border: .1rem solid var(--dividerColor);
    padding: 1rem;
    grid-gap: 1rem;
}


.publisher_card_left {
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    min-width: 7rem;
    min-height: 7rem;
    object-fit: cover;
    border: .15rem solid var(--primaryColor);
    background-color: var(--cardColor);
}

.publisher_card_right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.i_active i {
    color: var(--primaryColor);
}

@media screen and (max-width : 425px) {

    .action_button {
        padding: .3rem 1rem;
        border-radius: .3rem;
        justify-content: center;
        grid-gap: .5rem;
        align-items: center;
    }
}



@media screen and (max-width : 920px) {
    .details_displayer_screen_displayer {
        display: flex;
        flex-direction: column;
        grid-gap: .5rem;
        overflow: auto;
    }

    .details_displayer_screen_displayer_left,
    .details_displayer_screen_displayer_right {
        width: 100%;
    }

    .details_displayer_screen_displayer_left {
        height: 100%;
        min-height: 100%;
    }


    .details_displayer_screen_displayer_right {
        display: flex;
        flex-direction: column;
        row-gap: .2rem;
        height: fit-content;
        overflow: unset;
    }

}